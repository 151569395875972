import React from 'react'

const SkeletonLoader = () => {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 animate-pulse rounded-md p-2">
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
        <div className="h-2 mb-2 bg-gray-300 rounded"></div>
    </div>
  )
}

export default SkeletonLoader
