import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import { MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';
import Pagination from '../Pagination';

const UserWallet = () => {
  const [wallet, setWallet] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const { id } = useParams();

  // getting wallet by user id
  const getWalletById = useCallback(async () => {
    try {
      const response = await axios.get(`/transaction?filter_value=${id}&type=in_wallet`);
      setWallet(response.data.Data);
      setTotal(response.data.Total);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getWalletById();
  }, [getWalletById]);

  return (
    <div>
      <div className='mx-auto py-2'>
        <div className='bg-white border border-[#e0e2e7] rounded-lg p-4 xl:w-[78vw] 2xl:w-full'>
          <div className='flex flex-wrap items-center justify-between py-3'>
            <button className='bg-[#fb0000] text-white px-5 py-2 rounded-lg hover:bg-[#e85959]'>Wallet Deposit</button>
            <h5 className='text-[#fb0000]'>Showing {wallet.length} wallet transactions</h5>
            <div className='py-2'>
              <form>
                <label htmlFor='search'><span className='hidden'>Search</span>
                  <input
                    type='search'
                    id='search'
                    className='px-3 py-1.5 border bg-[#f2f9ff] border-slate-300 placeholder-slate-400 rounded-md focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] focus:ring-1'
                    required
                    placeholder='Search wallet'
                  />
                </label>
              </form>
            </div>
          </div>
          {loading
            ? (
              <div className='flex items-center justify-center bg-[#f1f4f6] h-[40vh]'>
                <Loader />
              </div>
              )
            : error
              ? (
                <div className='bg-[#f1f4f6] grid place-items-center h-[40vh]'>
                  <div className='grid place-items-center'>
                    <h2><MdOutlineBlock /></h2>
                    <h4>Error occurred while fetching data</h4>
                  </div>
                </div>
                )
              : (
                <div className='overflow-x-auto h-[40vh]'>
                  {wallet.length > 0
                    ? (
                      <table className='w-full text-left table-auto'>
                        <thead>
                          <tr className='border-b border-slate-500'>
                            <th className='p-2'>Account Number</th>
                            <th className='p-2'>Debit</th>
                            <th className='p-2'>Credit</th>
                            <th className='p-2'>Balance After</th>
                            <th className='p-2'>Balance After</th>
                            <th className='p-2'>Transaction Status</th>
                            <th className='p-2'>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {wallet.map((user) => (
                            <tr key={user.id}>
                              <td className='p-2 '>{user.account_number}</td>
                              <td className='p-2'>{user.debit}</td>
                              <td className='p-2'>{user.credit}</td>
                              <td className='p-2'>{user.balance_before}</td>
                              <td className='p-2'>{user.balance_after}</td>
                              <td className='p-2'>{user.transaction_status}</td>
                              <td className='p-2'>{new Date(user.transaction_timestamp).toISOString().replace('T', ' ').slice(0, 19)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      )
                    : (
                      <div className='bg-[#f1f4f6] grid place-items-center h-[40vh]'>
                        <div className='grid place-items-center'>
                          <h2><MdOutlineBlock /></h2>
                          <h4>No Data</h4>
                        </div>
                      </div>
                      )}
                </div>
                )}
          <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
            <div>
              <span className='mr-2'>Records per page:</span>
              <select
                className='px-3 py-1 border bg-[#f2f9ff] border-slate-300 rounded-md focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000]'
                value={recordsPerPage}
                onChange={(e) => {
                  setRecordsPerPage(parseInt(e.target.value, 10));
                  setCurrentPage(1);
                }}
              >
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='75'>75</option>
                <option value='100'>100</option>
              </select>
            </div>
            <Pagination
              nPages={Math.ceil(total / recordsPerPage)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWallet;
