import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import { MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';
import Pagination from '../Pagination';
import SkeletonLoader from '../SkeletonLoader'

const ViewLoan = () => {
  const [viewloan, setViewloan] = useState([]);
  const [viewloanlog, setViewloanlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const { id } = useParams();

  // getting loan details by id
  const getLoanById = useCallback(async () => {
    try {
      const response = await axios.get(`/loan?entity_id='${id}'`);
      setViewloan(response.data.Loan[0]);
      setTimeout(() => {
        setLoading(false)
      }, 1500);
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  }, [id]);

  useEffect(() => {
    getLoanById();
  }, [getLoanById]);

  // getting loan log details by id
  const getLoanLogById = useCallback(async () => {
    try {
      const offset = (currentPage - 1) * recordsPerPage;
      const response = await axios.get(`/loan_log?loan_id='${viewloan.id}'&page_size=${recordsPerPage}&page_number=${offset}`);
      setViewloanlog(response.data.LoanLog);
      setTotal(response.data.Total);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setLoading(false);
    }
  }, [viewloan.id, currentPage, recordsPerPage]);

  useEffect(() => {
    getLoanLogById();
  }, [getLoanLogById]);

  return (
    <div>
      <div className='mx-auto p-4'>
        <h3 className='text-[#fb0000]'><b>Loan Details</b></h3>
        {/* loan details */}
        <section className='bg-white p-4 border border-[#e0e2e7] shadow-sm rounded-lg my-2'>
          {loading || !viewloan ? (
            <SkeletonLoader/>
          ) : (
            <div className='grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4'>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Loan Start Date :</b></h5>
                <p>{new Date(viewloan.loan_start_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Loan End Date :</b></h5>
                <p>{new Date(viewloan.loan_end_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Initial Payment :</b></h5>
                <p>{viewloan.initial_payment}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Initial Count :</b></h5>
                <p>{viewloan.instalment_count}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Amount Disburse :</b></h5>
                <p>{viewloan.amount_disburse}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Loan Principle :</b></h5>
                <p>{viewloan.loan_principal}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Maximum Loan :</b></h5>
                <p>{viewloan.maximum_loan}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Principle Insatllment Balance :</b></h5>
                <p>{viewloan.principal_instalment_balance}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Loan Intrest :</b></h5>
                <p>{viewloan.loan_interest}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Intrest Last Date :</b></h5>
                <p>{new Date(viewloan.interest_last_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Loan Interest Balance :</b></h5>
                <p>{viewloan.loan_interest_bal}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Rollover Interest :</b></h5>
                <p>{viewloan.rollover_interest}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Rollover Interest Balance :</b></h5>
                <p>{viewloan.rollover_interest_bal}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Rollover Start Date :</b></h5>
                <p>{new Date(viewloan.rollover_start_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Rollover Next Date :</b></h5>
                <p>{new Date(viewloan.rollover_next_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Rollover End Date :</b></h5>
                <p>{new Date(viewloan.rollover_end_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Debt Collection Start Date:</b></h5>
                <p>{new Date(viewloan.debt_collection_start_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Debt Collection End Date:</b></h5>
                <p>{new Date(viewloan.debt_collection_end_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Application Fee Amount :</b></h5>
                <p>{viewloan.application_fee_amount}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Application Installment Fee Balance :</b></h5>
                <p>{viewloan.application_instalment_fee_bal}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Insurance Amount :</b></h5>
                <p>{viewloan.insurance_amount}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Description :</b></h5>
                <p>{viewloan.description}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Active Status :</b></h5>
                <p>{viewloan.active_status}</p>
              </div>
              <div className='flex items-center gap-2'>
                <h5 className='text-blue-600'><b>Status :</b></h5>
                <p>{viewloan.status}</p>
              </div>
            </div>
          )}
        </section>

        {/* loan logs */}
        <section className='bg-white p-4 border border-[#e0e2e7] shadow-sm rounded-lg my-4'>
          <h3 className='text-[#ff3366]'><b>Loan Logs</b></h3>
          {loading
            ? (
              <div className='flex items-center justify-center bg-[#f1f4f6] h-screen md:h-[56em] lg:h-[25em] xl:h-[35em] 2xl:lg:h-[48em]'>
                <Loader />
              </div>
              )
            : error
              ? (
                <div className='bg-[#f1f4f6] grid place-items-center h-screen md:h-[56em] lg:h-[25em] xl:h-[35em] 2xl:lg:h-[48em]'>
                  <div className='grid place-items-center'>
                    <h2><MdOutlineBlock /></h2>
                    <h4>Error occurred while fetching data</h4>
                  </div>
                </div>
                )
              : (
                <div className='overflow-x-auto'>
                  {viewloanlog.length > 0
                    ? (
                      <table className='w-full text-left table-auto'>
                        <thead>
                          <tr className='border-b border-slate-500'>
                            <th className='p-2'>Amount</th>
                            <th className='p-2'>Narative</th>
                            <th className='p-2'>Time Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          {viewloanlog.map((loanlog) => (
                            <tr key={loanlog.id}>
                              <td className='p-2 '>{loanlog.amount}</td>
                              <td className='p-2'>{loanlog.narrative}</td>
                              <td className='p-2'>{new Date(loanlog.date_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      )
                    : (
                      <div className='bg-[#f1f4f6] grid place-items-center h-screen md:h-[56em] lg:h-[25em] xl:h-[35em] 2xl:lg:h-[48em]'>
                        <div className='grid place-items-center'>
                          <h2><MdOutlineBlock /></h2>
                          <h4>No Data</h4>
                        </div>
                      </div>
                      )}
                </div>
                )}
          <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
            <div>
              <span className='mr-2'>Records per page:</span>
              <select
                className='px-3 py-1 border bg-[#f2f9ff] border-slate-300 rounded-md focus:outline-none focus:border-[#85d6e3] focus:ring-[#85d6e3]'
                value={recordsPerPage}
                onChange={(e) => {
                  setRecordsPerPage(parseInt(e.target.value, 10));
                  setCurrentPage(1);
                }}
              >
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='75'>75</option>
                <option value='100'>100</option>
              </select>
            </div>
            <Pagination
              nPages={Math.ceil(total / recordsPerPage)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default ViewLoan;
