import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import UserWallet from './UserWallet';
import UserAccounts from './UserAccount';
import UserLoans from './UserLoans';
import UserLoanRequests from './UserLoanRequests';

const Loader = () => (
  <div className="animate-pulse rounded-md p-2">
    <div className="h-3 mb-2 bg-gray-300 rounded"></div>
    <div className="h-3 mb-2 bg-gray-300 rounded"></div>
    <div className="h-3 mb-2 bg-gray-300 rounded"></div>
    <div className="h-3 mb-2 bg-gray-300 rounded"></div>
    <div className="h-3 bg-gray-300 rounded"></div>
  </div>
);

const ViewUser = () => {
  const [viewuser, setViewuser] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  // getting viewuser by id
  const getUserById = useCallback(async () => {
    try {
      const response = await axios.get(`/entity?entity_id=${id}`);
      setViewuser(response.data.Data[0]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  }, [id]);

  useEffect(() => {
    getUserById();
  }, [getUserById]);

  return (
    <div>
      <div className='mx-auto p-4'>
        <h3 className='text-[#fb0000]'><b>User Profile</b></h3>
        <div className='grid grid-cols-1 gap-4 mt-2 md:grid-cols-2 xl:grid-cols-3'>
          <div className='bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-lg'>
            <h4><b><u>Personal Information</u></b></h4>
            {loading || !viewuser ? (
              <Loader />
            ) : (
            <div className='space-y-1'>
              <div className='flex items-center gap-4'>
                <span className='text-blue-600 font-bold'>First Name :</span>
                <p>{viewuser.first_name}</p>
              </div>
              <div className='flex items-center gap-4'>
                <span className='text-blue-600 font-bold'>Other Names :</span>
                <p>{viewuser.other_names}</p>
              </div>
              <div className='flex items-center gap-4'>
                <span className='text-blue-600 font-bold'>ID Number :</span>
                <p>{viewuser.id_number}</p>
              </div>
              <div className='flex items-center gap-4'>
                <span className='text-blue-600 font-bold'>Phone Number :</span>
                <p>{viewuser.msisdn}</p>
              </div>
              <div className='flex items-center gap-4'>
                <span className='text-blue-600 font-bold'>Role :</span>
                <p>{viewuser.role}</p>
              </div>
            </div>
          )}
          </div>
          <div className='bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-lg'>
            <h4><b><u>Wallet</u></b></h4>
            <span className='text-blue-600 font-bold'>Balance:</span>
            <span> KSH 0</span>
          </div>
          <div className='bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-lg'>
            <h4><b><u>Available Savings</u></b></h4>
            <span className='text-blue-600 font-bold'>Balance:</span>
            <span> KSH 0</span>
          </div>
        </div>

        <div role='tablist' className='tabs tabs-bordered py-6'>
          <input type='radio' name='my_tabs_1' role='tab' className='tab checked:text-[#fb0000] checked:font-bold' aria-label='Account' defaultChecked />
          <div role='tabpanel' className='tab-content'>
            {/* user accounts */}
            <UserAccounts />
          </div>

          <input type='radio' name='my_tabs_1' role='tab' className='tab checked:text-[#fb0000] checked:font-bold' aria-label='Wallet' />
          <div role='tabpanel' className='tab-content'>
            {/* user wallet */}
            <UserWallet />
          </div>

          <input type='radio' name='my_tabs_1' role='tab' className='tab checked:text-[#fb0000] checked:font-bold' aria-label='Loans' />
          <div role='tabpanel' className='tab-content'>
            {/* user loans */}
            <UserLoans />
          </div>

          <input type='radio' name='my_tabs_1' role='tab' className='tab whitespace-nowrap checked:text-[#fb0000] checked:font-bold' aria-label='Loan Requests' />
          <div role='tabpanel' className='tab-content'>
            {/* user loan requests */}
            <UserLoanRequests />
          </div>
        </div>

      </div>
    </div>
  );
};

export default ViewUser;
