import React from 'react';
import { FaUsers, FaUserSlash, FaUsersCog, FaUserShield, FaUserEdit, FaMoneyCheck } from 'react-icons/fa';
import { MdPayments } from 'react-icons/md';
import { RiUserShared2Fill } from 'react-icons/ri';

const Home = () => {
  return (
    <main>
      <div className='mx-auto p-4'>
        <section className='flex flex-wrap items-center justify-between'>
          <h3 className='text-[#fb0000]'><b>Admin DashBoard</b></h3>
          <div className='flex gap-2 w-full md:w-1/2 xl:w-1/3'>
            <div className='w-full'>
              <label htmlFor='startdate'>Start Date
                <input
                  type='date'
                  required
                  className='px-3 py-1.5 bg-inherit border-2 shadow-sm border-[#fb0000] placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] block w-full rounded-md sm:text-sm focus:ring-1'
                  // value={first_name}
                  // onChange={(e) => setFirst_name(e.target.value)}
                />
              </label>
            </div>
            <div className='w-full'>
              <label htmlFor='enddate'>End Date
                <input
                  type='date'
                  required
                  className='px-3 py-1.5 bg-inherit border-2 shadow-sm border-[#fb0000] placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] block w-full rounded-md sm:text-sm focus:ring-1'
                  // value={first_name}
                  // onChange={(e) => setFirst_name(e.target.value)}
                />
              </label>
            </div>
          </div>
        </section>

        {/* statistics */}
        <section className='grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4'>
          <div className='flex items-center gap-4 bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-md'>
            <div className='bg-blue-700 text-white p-4 rounded-lg'>
              <span className='text-4xl'><FaUsers /></span>
            </div>
            <div>
              <h4><b>All Members</b></h4>
              <p className='text-blue-700 font-bold'>234,230</p>
            </div>
          </div>
          <div className='flex items-center gap-4 bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-md'>
            <div className='bg-green-600 text-white p-4 rounded-lg'>
              <span className='text-4xl'><FaUserEdit /></span>
            </div>
            <div>
              <h4><b>Fully Registered Members</b></h4>
              <p className='text-green-600 font-bold'>123</p>
            </div>
          </div>
          <div className='flex items-center gap-4 bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-md'>
            <div className='bg-yellow-600 text-white p-4 rounded-lg'>
              <span className='text-4xl'><FaUserShield /></span>
            </div>
            <div>
              <h4><b>Blocked Numbers</b></h4>
              <p className='text-yellow-600 font-bold'>10</p>
            </div>
          </div>
          <div className='flex items-center gap-4 bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-md'>
            <div className='bg-red-600 text-white p-4 rounded-lg'>
              <span className='text-4xl'><RiUserShared2Fill /></span>
            </div>
            <div>
              <h4><b>Exited Members</b></h4>
              <p className='text-red-600 font-bold'>110</p>
            </div>
          </div>
          <div className='flex items-center gap-4 bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-md'>
            <div className='bg-orange-600 text-white p-4 rounded-lg'>
              <span className='text-4xl'><FaUserSlash /></span>
            </div>
            <div>
              <h4><b>Suspended Members</b></h4>
              <p className='text-orange-600 font-bold'>230</p>
            </div>
          </div>
          <div className='flex items-center gap-4 bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-md'>
            <div className='bg-blue-500 text-white p-4 rounded-lg'>
              <span className='text-4xl'><FaUsersCog /></span>
            </div>
            <div>
              <h4><b>Pending Members</b></h4>
              <p className='text-blue-500 font-bold'>50</p>
            </div>
          </div>
          <div className='flex items-center gap-4 bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-md'>
            <div className='bg-purple-600 text-white p-4 rounded-lg'>
              <span className='text-4xl'><FaMoneyCheck /></span>
            </div>
            <div>
              <h4><b>Total Loan Taken</b></h4>
              <p className='text-purple-600 font-bold'>4550</p>
            </div>
          </div>
          <div className='flex items-center gap-4 bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-md'>
            <div className='bg-pink-600 text-white p-4 rounded-lg'>
              <span className='text-4xl'><MdPayments /></span>
            </div>
            <div>
              <h4><b>Total Loan Repaid</b></h4>
              <p className='text-pink-600 font-bold'>3430</p>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Home;
