import React from 'react';

const Loader = () => {
  return (
    <div>
      <span className='loading loading-dots loading-lg' />
    </div>
  );
};

export default Loader;
