import React from 'react';

const Profile = () => {
  // getting current user
  const currentUser = window.localStorage.getItem('token');
  const user = JSON.parse(currentUser).data.PayLoad;

  return (
    <div>
      <div className='mx-auto p-4'>
        <h3><b>Profile</b></h3>
        <p className='text-[#fb0000]'>DashBoard / Profile</p>
        <div className='bg-white border border-[#e0e2e7] shadow-sm rounded-md p-4 my-4 w-1/4'>
          <h4><b><u>Personal Information</u></b></h4>
          <div className='space-y-1'>
            <div className='flex items-center gap-4'>
              <span>First Name :</span>
              <p className='text-blue-600 font-bold'>{user.first_name}</p>
            </div>
            <div className='flex items-center gap-4'>
              <span>Other Names :</span>
              <p className='text-blue-600 font-bold'>{user.other_names}</p>
            </div>
            <div className='flex items-center gap-4'>
              <span>ID Number :</span>
              <p className='text-blue-600 font-bold'>{user.id_number}</p>
            </div>
            <div className='flex items-center gap-4'>
              <span>Phone Number :</span>
              <p className='text-blue-600 font-bold'>{user.msisdn}</p>
            </div>
            <div className='flex items-center gap-4'>
              <span>Role :</span>
              <p className='text-blue-600 font-bold'>{user.role}</p>
            </div>
          </div>
        </div>
        {/* <div className='bg-white rounded-md p-4 mt-4 w-1/3'>
          <h4><b><u>Next Of Kin</u></b></h4>
          <div className='space-y-1'>
            <div className="flex items-center gap-4">
              <span>Name :</span>
              <p className='text-blue-600 font-bold'>{user.first_name}</p>
            </div>
            <div className="flex items-center gap-4">
              <span>Other Names :</span>
              <p className='text-blue-600 font-bold'>{user.first_name}</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Profile;
