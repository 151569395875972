import React, { useState, useEffect } from 'react';
import axios from '../api/api';
import { toast } from 'sonner';
import { Link, useNavigate } from 'react-router-dom';
import AuthToken from '../components/Context/AuthToken';
import PropTypes from 'prop-types';

const Login = ({ setAccessToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { accessToken } = AuthToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = await axios.post('/login',
        { username, password }
      );
      setAccessToken(token);
      toast.success('Login Sucessful');
      navigate('/app/dashboard');
    } catch (error) {
      console.log(error);
      toast.error('Login Failed');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      navigate('/app/dashboard');
    }
  });

  return (
    <main className='bg-[#f8f8f8]'>
      <div className='flex flex-wrap items-center justify-center gap-4 h-screen md:h-screen lg:h-[100vh]'>
        <div className='p-4 rounded-lg w-full md:w-2/3 lg:w-1/3 xl:w-1/3'>
          <form onSubmit={handleSubmit}>
            <div className='text-center p-3'>
              <h2><b>Mini <span className='text-[#fb0000]'>Loan</span></b></h2>
              <span className='text-lg font-light'>Welcome to Mini Loan dashboard</span>
            </div>
            <div className='my-3'>
              <label htmlFor='username'><span>ID Number</span>
                <input
                  type='text'
                  required
                  placeholder='ID Number'
                  className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] block w-full rounded-md sm:text-sm focus:ring-1'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </label>
            </div>
            <div className='my-3'>
              <label htmlFor='pin'><span>Pin</span>
                <input
                  type='password'
                  required
                  placeholder='****'
                  className='px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] block w-full rounded-md sm:text-sm focus:ring-1'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
            </div>
            <div className='my-3'>
              <button disabled={loading} className='bg-[#fb0000] text-white px-5 py-2 rounded-lg text-md w-full hover:bg-[#e85959] disabled:bg-[#e85959]'>{loading ? 'Please Wait...' : 'Login'}</button>
            </div>
            <div className='text-center'>
              <Link to='/forgot-password' className='text-[#fb0000] hover:underline hover:text-[#e85959]'>Forgot Password?</Link>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Login;

Login.propTypes = {
  setAccessToken: PropTypes.func
};
