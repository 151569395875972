import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import SkeletonLoader from '../SkeletonLoader'

const ViewProduct = () => {
  const [viewproduct, setViewproduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  // getting product details by id
  const getProductById = useCallback(async () => {
    try {
      const response = await axios.get(`/product?id=${id}`);
      setViewproduct(response.data.Loan[0]);
     
        setLoading(false);
     
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  }, [id]);

  useEffect(() => {
    getProductById();
  }, [getProductById]);

  return (
    <div>
      <div className='mx-auto p-4'>
        <h3 className='text-[#fb0000]'><b>Product Details</b></h3>
        <div className='bg-white border border-[#e0e2e7] shadow-sm p-4 rounded-lg my-2'>
          {loading || !viewproduct ? (
            <SkeletonLoader/>
          ) : (
            <div>
              <div className='grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 whitespace-nowrap'>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Name :</b></h5>
                  <p>{viewproduct.loan_name}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Publisher Name :</b></h5>
                  <p>{viewproduct.publisher_name}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Type :</b></h5>
                  <p>{viewproduct.loan_type}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Duration :</b></h5>
                  <p>{viewproduct.loan_duration}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Agent :</b></h5>
                  <p>{viewproduct.loan_agent}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Approval :</b></h5>
                  <p>{viewproduct.loan_approval}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Approval Date :</b></h5>
                  <p>{new Date(viewproduct.loan_approval_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Colleteral :</b></h5>
                  <p>{viewproduct.loan_colleteral}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Intrest Frequency :</b></h5>
                  <p>{viewproduct.loan_interest_frequency}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Intrest Rate :</b></h5>
                  <p>{viewproduct.loan_interest_rate}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Request Date :</b></h5>
                  <p>{new Date(viewproduct.loan_request_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Loan Start Date :</b></h5>
                  <p>{new Date(viewproduct.loan_start_date).toISOString().replace('T', ' ').slice(0, 19)}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Maximun Loan Amount :</b></h5>
                  <p>{viewproduct.maximum_loan_amount}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Minimum Loan Amount :</b></h5>
                  <p>{viewproduct.minimum_loan_amount}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Currency :</b></h5>
                  <p>{viewproduct.currency}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Intrest Type :</b></h5>
                  <p>{viewproduct.interest_type}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Notification Frequency :</b></h5>
                  <p>{viewproduct.notification_freq}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Rollover Frequency :</b></h5>
                  <p>{viewproduct.rollover_frequency}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Rollover Interest Rate :</b></h5>
                  <p>{viewproduct.rollover_interest_rate}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <h5 className='text-blue-600'><b>Rollover Times :</b></h5>
                  <p>{viewproduct.rollover_times}</p>
                </div>
              </div>
              <div className='flex items-center gap-2 my-4'>
                <h5 className='text-blue-600'><b>Priority Rule :</b></h5>
                <p>{viewproduct.priority_rule}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
