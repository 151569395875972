import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import { MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';
import Pagination from '../Pagination';

const UserAccounts = () => {
  const [account, setAccount] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const { id } = useParams();

  // Fetch All accounts
  const getAccounts = useCallback(async () => {
    try {
      const response = await axios.get(`/account?type="in_Wallet"&entity_id='${id}'&page_number=${currentPage}&page_size=${recordsPerPage}`);
      setAccount(response.data.Loan);
      setTotal(response.data.Total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  }, [currentPage, recordsPerPage, id]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  return (
    <div className='mx-auto py-2'>
      <div className='bg-white rounded-lg p-4 border border-[#e0e2e7] lg:w-[80vw] xl:w-[78vw] 2xl:w-full'>
        <div className='flex flex-wrap items-center justify-between '>
          <div>
            <button className='bg-[#fb0000] hover:bg-[#e85959] text-white px-5 py-2 rounded-lg' onClick={() => document.getElementById('my_modal_3').showModal()}>Create Account</button>
            <dialog id='my_modal_3' className='modal'>
              <div className='modal-box'>
                <form method='dialog'>
                  {/* if there is a button in form, it will close the modal */}
                  <button className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2'>✕</button>
                </form>
                <h3 className='font-bold text-lg'>Add Account!</h3>
                <div className='py-4'>
                  <form action=''>
                    <label htmlFor='account'>
                      <select
                        name='' id=''
                        required
                        className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                      >
                        <option value=''>Account Type</option>
                        <option value='Shareholder'>Shareholder</option>
                        <option value='SMS Supplier'>SMS Supplier</option>
                        <option value='Airtime suplier'>Airtime suplier</option>
                        <option value='Airtime inventory'>Airtime inventory</option>
                      </select>
                    </label>
                    <div className='mt-2 float-right'>
                      <button className='bg-blue-700 hover:bg-blue-500 text-white px-5 py-2 rounded-lg'>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </dialog>
          </div>
          <h5 className='text-[#fb0000]'>Showing {account.length} account</h5>
          <div className='py-2'>
            <form>
              <label htmlFor='search'><span className='hidden'>Search</span>
                <input
                  type='search'
                  id='search'
                  className='px-3 py-1.5 border bg-[#f2f9ff] border-slate-300 placeholder-slate-400 rounded-md focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] focus:ring-1'
                  required
                  placeholder='Search account'
                  // value={searchUser}
                  // onChange={search.bind(this)}
                />
              </label>
            </form>
          </div>
        </div>
        {loading
          ? (
            <div className='flex items-center justify-center bg-[#f1f4f6] h-[40vh]'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f1f4f6] grid place-items-center h-[40vh]'>
                <div className='grid place-items-center'>
                  <h2><MdOutlineBlock /></h2>
                  <h4>Error occurred while fetching data</h4>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto h-[40vh]'>
                {account.length > 0
                  ? (
                    <table className='w-full text-left table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='p-2'>Account Number</th>
                          <th className='p-2'>Account Name</th>
                          <th className='p-2'>Account Type</th>
                          <th className='p-2'>Account Balance</th>
                          <th className='p-2'>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {account.map((user) => (
                          <tr key={user.id}>
                            <td className='p-2 '>{user.account_number}</td>
                            <td className='p-2'>{user.account_name}</td>
                            <td className='p-2'>{user.type}</td>
                            <td className='p-2'>{user.account_balance}</td>
                            <td className='p-2'>{new Date(user.start_date).toISOString().replace('T', ' ').slice(0, 19)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f1f4f6] grid place-items-center h-[40vh]'>
                      <div className='grid place-items-center'>
                        <h2><MdOutlineBlock /></h2>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2f9ff] border-slate-300 rounded-md focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000]'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(total / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default UserAccounts;
