import React, { useState } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

const CreateProduct = () => {
  // getting current user
  const currentUser = window.localStorage.getItem('token');
  const user = JSON.parse(currentUser).data.PayLoad;

  const [entity_id, setEntity_id] = useState(user.id);
  const [product_owner_id, setProduct_owner_id] = useState(user.id);
  const [msisdn, setMsisdn] = useState(user.msisdn);
  const [loan_type, setLoan_type] = useState('');
  const [loan_name, setLoan_name] = useState('');
  const [publisher_name, setPublisher_name] = useState('');
  const [currency, setCurrency] = useState('');
  const [loan_duration, setLoan_duration] = useState('');
  const [maximum_loan_amount, setMaximum_loan_amount] = useState('');
  const [minimum_loan_amount, setMinimum_loan_amount] = useState('');
  const [instalment_counts, setInstalment_counts] = useState('');
  const [interest_type, setInterest_type] = useState('');
  const [loan_interest_frequency, setLoan_interest_frequency] = useState('');
  const [loan_interest_rate, setLoan_interest_rate] = useState('');
  const [application_fee_p, setApplication_fee_p] = useState('');
  const [insurance_fee, setInsurance_fee] = useState('');
  const [upfront, setUpfront] = useState('');
  const [early_repayment, setEarly_repayment] = useState('');
  const [early_repayment_plan, setEarly_repayment_plan] = useState('');
  const [notification_freq, setNotification_freq] = useState('');
  const [rollover_days, setRollover_days] = useState('');
  const [rollover_frequency, setRollover_frequency] = useState('');
  const [rollover_interest_rate, setRollover_interest_rate] = useState('');
  const [grace_period_b_rollover, setGrace_period_b_rollover] = useState('');
  const [grace_period_interest, setGrace_period_interest] = useState('');
  const [debt_collection, setDebt_collection] = useState('');
  const [priority_rule, setPriority_rule] = useState('');
  const [loan_agent, setLoan_agent] = useState('');
  const [top_up, setTop_up] = useState('');
  const [loan_colleteral, setLoan_colleteral] = useState('');
  const [other_charges, setOther_charges] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/create_product',
        {
          entity_id,
          product_owner_id,
          msisdn,
          loan_type,
          loan_name,
          publisher_name,
          currency,
          loan_duration,
          maximum_loan_amount,
          minimum_loan_amount,
          instalment_counts,
          interest_type,
          loan_interest_frequency,
          loan_interest_rate,
          application_fee_p,
          insurance_fee,
          early_repayment,
          early_repayment_plan,
          notification_freq,
          rollover_days,
          rollover_frequency,
          grace_period_b_rollover,
          grace_period_interest,
          debt_collection,
          priority_rule,
          loan_agent,
          top_up,
          loan_colleteral,
          other_charges
        }
      );
      toast.success('Product created');
      navigate('/app/products');
    } catch (error) {
      console.log(error);
      toast.error('Failed to create product');
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg border border-[#e0e2e7] shadow-lg w-full'>
        <h3 className='text-xl font-bold text-[#fb0000] my-4'>Add New Product.</h3>
        <hr className='border-[#fb0000]' />
        <form onSubmit={handleSubmit}>
          <div className='mt-2 hidden'>
            <label htmlFor='entityid'>entityid
              <input
                type='text'
                disabled
                required
                placeholder='entityid'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                value={entity_id}
                onChange={(e) => setEntity_id(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2 hidden'>
            <label htmlFor='product owner'>product owner
              <input
                type='text'
                disabled
                required
                placeholder='product owner'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                value={product_owner_id}
                onChange={(e) => setProduct_owner_id(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2 hidden'>
            <label htmlFor='msisdn'>msisdn
              <input
                type='number'
                disabled
                required
                placeholder='msisdn'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                value={msisdn}
                onChange={(e) => setMsisdn(e.target.value)}
              />
            </label>
          </div>
          <div className='grid grid-cols-2 gap-4 mb-5 xl:grid-cols-4'>
            <div className='mt-2'>
              <label htmlFor='loantype'>Loan Type
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={loan_type}
                  onChange={(e) => setLoan_type(e.target.value)}
                >
                  <option value=''>Loan Type</option>
                  <option value='Postpaid'>Postpaid</option>
                  <option value='Prepaid'>Prepaid</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Loan Name'>Loan Name
                <input
                  type='text'
                  required
                  placeholder='Loan Name'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={loan_name}
                  onChange={(e) => setLoan_name(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Publisher Name'>Publisher Name
                <input
                  type='text'
                  required
                  placeholder='Publisher Name'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={publisher_name}
                  onChange={(e) => setPublisher_name(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='currency'>Currency
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option value=''>Currency</option>
                  <option value='KES'>KES</option>
                  <option value='USD'>USD</option>
                  <option value='EUR'>EUR</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='loanduration'>Loan Duration
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={loan_duration}
                  onChange={(e) => setLoan_duration(e.target.value)}
                >
                  <option value=''>Loan Duration</option>
                  <option value='Day'>Day</option>
                  <option value='Week'>Week</option>
                  <option value='Two Weeks'>Two Weeks</option>
                  <option value='Three Weeks'>Three Weeks</option>
                  <option value='Month'>Month</option>
                  <option value='Three Months'>Three Months</option>
                  <option value='Six Months'>Six Months</option>
                  <option value='Year'>Year</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Maximum Loan Amount'>Maximum Loan Amount
                <input
                  type='number'
                  required
                  placeholder='Maximum Loan Amount'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={maximum_loan_amount}
                  onChange={(e) => setMaximum_loan_amount(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Minimum Loan Amount'>Minimum Loan Amount
                <input
                  type='number'
                  required
                  placeholder='Minimum Loan Amount'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={minimum_loan_amount}
                  onChange={(e) => setMinimum_loan_amount(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Minimum Installment'>Minimum Installment count
                <input
                  type='number'
                  required
                  placeholder='Minimum Installment count'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={instalment_counts}
                  onChange={(e) => setInstalment_counts(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='intrest type'>Intrest Type
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={interest_type}
                  onChange={(e) => setInterest_type(e.target.value)}
                >
                  <option value=''>Intrest Type</option>
                  <option value='Reducing Balance'>Reducing Balance</option>
                  <option value='Static'>Static</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Loan Interest Frequency'>Loan Interest Frequency
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={loan_interest_frequency}
                  onChange={(e) => setLoan_interest_frequency(e.target.value)}
                >
                  <option value=''>Loan Interest Frequency</option>
                  <option value='Daily'>Daily</option>
                  <option value='Weekly'>Weekly</option>
                  <option value='Monthly'>Monthly</option>
                  <option value='Two months'>Two months</option>
                  <option value='Three months'>Three months</option>
                  <option value='Six months'>Six months</option>
                  <option value='One Year'>One Year</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Loan Interest rate'>Loan Interest rate
                <input
                  type='number'
                  required
                  placeholder='Loan Interest Rate'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={loan_interest_rate}
                  onChange={(e) => setLoan_interest_rate(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Application Fee percentage'>Application Fee percentage
                <input
                  type='number'
                  required
                  placeholder='Application Fee percentage'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={application_fee_p}
                  onChange={(e) => setApplication_fee_p(e.target.value)}
                />
              </label>
            </div>
          </div>
          <hr className='border-[#fb0000]' />
          <div className='grid grid-cols-2 gap-4 my-5 xl:grid-cols-4'>
            <div className='mt-2'>
              <label htmlFor='Insurance Fee'>Insurance Fee
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={insurance_fee}
                  onChange={(e) => setInsurance_fee(e.target.value)}
                >
                  <option value=''>Insurance Fee</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='upfront'>Upfront
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={upfront}
                  onChange={(e) => setUpfront(e.target.value)}
                >
                  <option value=''>Upfront?</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='earlyrepayment'>Early Repayment
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={early_repayment}
                  onChange={(e) => setEarly_repayment(e.target.value)}
                >
                  <option value=''>Early Repayment</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Early Repaymentplan'>Early Repayment Plan
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={early_repayment_plan}
                  onChange={(e) => setEarly_repayment_plan(e.target.value)}
                >
                  <option value=''>Early Repayment Plan</option>
                  <option value='days_in_loan'>Days in loan</option>
                  <option value='full_loan'>Full loan</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Notification Frequency'>Notification Frequency
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={notification_freq}
                  onChange={(e) => setNotification_freq(e.target.value)}
                >
                  <option value=''>Notification Frequency</option>
                  <option value='hourly'>Hourly</option>
                  <option value='daily'>Daily</option>
                  <option value='weekly'>Weekly</option>
                  <option value='two_weeks'>Two weeks</option>
                  <option value='monthly'>Monthly</option>
                  <option value='Three Months'>Three Months</option>
                  <option value='Six Months'>Six Months</option>
                  <option value='Yearly'>Yearly</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Rollover Days'>Rollover Days
                <input
                  type='number'
                  required
                  placeholder='Rollover Days'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={rollover_days}
                  onChange={(e) => setRollover_days(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Rollover frequency'>Rollover Frequency
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={rollover_frequency}
                  onChange={(e) => setRollover_frequency(e.target.value)}
                >
                  <option value=''>Rollover Frequency</option>
                  <option value='daily'>Daily</option>
                  <option value='weekly'>Weekly</option>
                  <option value='monthly'>Monthly</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Rolloverrate'>Rollover Interest Rate
                <input
                  type='number'
                  required
                  placeholder='Rollover Interest Rate'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={rollover_interest_rate}
                  onChange={(e) => setRollover_interest_rate(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Grace Period Interest'>Grace Period Before Rollover
                <input
                  type='number'
                  required
                  placeholder='Grace Period Before Rollover'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={grace_period_b_rollover}
                  onChange={(e) => setGrace_period_b_rollover(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Grace Period Interest'>Grace Period Interest
                <input
                  type='number'
                  required
                  placeholder='Grace Period Interest'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={grace_period_interest}
                  onChange={(e) => setGrace_period_interest(e.target.value)}
                />
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Debt Collection'>Debt Collection
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={debt_collection}
                  onChange={(e) => setDebt_collection(e.target.value)}
                >
                  <option value=''>Debt Collection</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Priority Rule'>Priority Rule
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={priority_rule}
                  onChange={(e) => setPriority_rule(e.target.value)}
                >
                  <option value=''>Priority Rule</option>
                  <option value='principle'>principle</option>
                  <option value='application_fee'>application_fee</option>
                  <option value='other_charges'>other_charges</option>
                  <option value='insurance'>insurance</option>
                  <option value='intrest'>intrest</option>
                  <option value='rollover'>rollover</option>
                  <option value='debt_collection'>debt_collection</option>
                </select>
              </label>
            </div>
          </div>
          <hr className='border-[#fb0000]' />
          <div className='grid grid-cols-2 gap-4 my-5 xl:grid-cols-4'>
            <div className='mt-2'>
              <label htmlFor='loanagent'>Loan Agent
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={loan_agent}
                  onChange={(e) => setLoan_agent(e.target.value)}
                >
                  <option value=''>Loan Agent</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Top Up'>Top Up
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={top_up}
                  onChange={(e) => setTop_up(e.target.value)}
                >
                  <option value=''>Top Up</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Loan Collateral'>Loan Collateral
                <select
                  name='' id=''
                  required
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={loan_colleteral}
                  onChange={(e) => setLoan_colleteral(e.target.value)}
                >
                  <option value=''>Loan Collateral</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </label>
            </div>
            <div className='mt-2'>
              <label htmlFor='Other Charges'>Other Charges
                <input
                  type='number'
                  placeholder='Other Charges'
                  className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#fb0000] focus:ring-[#fb0000] w-full rounded-md focus:ring-1'
                  value={other_charges}
                  onChange={(e) => setOther_charges(e.target.value)}
                />
              </label>
            </div>
          </div>
          <div className='py-3'>
            <button type='submit' className='bg-[#fb0000] text-white px-5 py-1 w-full hover:bg-[#e85959]'>Create Product</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateProduct;
