import Topnav from './components/Nav/TopBar';
import Sidebar from './components/Nav/SideBar';
import { useStateContext } from './components/Context/ContextProvider';
import AuthToken from './components/Context/AuthToken';
import ProtectedRoute from './components/Context/ProtectedRoute';
import { Routes, Route } from 'react-router-dom';

import Login from './pages/Login';
import Unauthorised from './pages/Unauthorised';
import Missing from './pages/Missing';

import Home from './pages/Home';
import Users from './pages/Users/Users';
import AddUser from './pages/Users/AddUser';
import ViewUser from './pages/Users/ViewUser';
import Profile from './pages/Users/Profile';
import Products from './pages/Products/Products';
import CreateProduct from './pages/Products/CreateProduct';
import ViewProduct from './pages/Products/ViewProduct';
import Loans from './pages/Loans/Loans';
import ViewLoan from './pages/Loans/ViewLoan';
import LoanRequests from './pages/Loans/LoanRequests';
import Transactions from './pages/Account/Transactions';
import Accounts from './pages/Account/Accounts';
import Report from './pages/Reports/Reports';

const Layout = () => {
  const { activeMenu } = useStateContext();
  const { accessToken, setAccessToken } = AuthToken();
  if (!accessToken) {
    return <Login setAccessToken={setAccessToken} />;
  }

  return (
    <main>
      <div className='flex relative'>
        {activeMenu
          ? (
            <div className='w-60 fixed'>
              <Sidebar />
            </div>
            )
          : (
            <div>
              <Sidebar />
            </div>
            )}
        <div
          className={
              activeMenu
                ? 'min-h-screen md:ml-60 w-full'
                : 'w-full min-h-screen flex-2'
            }
        >
          <div>
            <Topnav />
          </div>
          <div>
            <Routes>
              {/* public routes */}
              <Route path='/dashboard' element={<Home />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/unauthorised' element={<Unauthorised />} />
              <Route path='*' element={<Missing />} />

              {/* admin only routes */}
              <Route path='/users' element={<ProtectedRoute><Users /></ProtectedRoute>} />
              <Route path='/adduser' element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
              <Route path='/user/:id' element={<ProtectedRoute><ViewUser /></ProtectedRoute>} />
              <Route path='/products' element={<ProtectedRoute><Products /></ProtectedRoute>} />
              <Route path='/addproduct' element={<ProtectedRoute><CreateProduct /></ProtectedRoute>} />
              <Route path='/product/:id' element={<ProtectedRoute><ViewProduct /></ProtectedRoute>} />
              <Route path='/loans' element={<ProtectedRoute><Loans /></ProtectedRoute>} />
              <Route path='/loan/:id' element={<ProtectedRoute><ViewLoan /></ProtectedRoute>} />
              <Route path='/loan-requests' element={<ProtectedRoute><LoanRequests /></ProtectedRoute>} />
              <Route path='/transactions' element={<ProtectedRoute><Transactions /></ProtectedRoute>} />
              <Route path='/accounts' element={<ProtectedRoute><Accounts /></ProtectedRoute>} />
              <Route path='/reports' element={<ProtectedRoute><Report /></ProtectedRoute>} />

            </Routes>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Layout;
